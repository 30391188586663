<template>
  <div style="width: 100%; position: relative; padding: 0 0 2rem">
    <display-module :titleInfo="titleInfo" style="margin-bottom: 1.066667rem" v-show="showFlag"/>
    <div class="MCopyrightBox animate__animated animate__fadeIn animate__slow" v-show="showFlag">
      <div
        class="MCopyrightItem"
        v-for="(item, index) in itemList"
        :key="index"
      >
        <!-- <img :src="item.img" /> -->
        <img-item :imgName="item.img" />
        <div class="Medium">{{ item.name }}</div>
      </div>
      <!-- <div style="height:100%;width:.666667rem;color:#fff;">1</div> -->
    </div>
  </div>
</template>

<script>
import displayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    displayModule,
  },
  props:{
    showFlag : Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "copyright",
        CHSTitle: "软件版权著作",
        instructions:
          "服务客户500+，覆盖众多主流行业，更多客户正在加入我们的合作",
      },
      itemList: [
        {
          img:
            "编组 17",
          name: "升学派位管理系统",
        },
        {
          img:
            "位图备份",
          name: "招生摇号软件",
        },
        {
          img:
            "位图备份 2",
          name: "多创OA管理系统",
        },
        {
          img: "位图备份 3",
          name: "多创文件管理系统",
        },
        {
          img:
            "位图备份 4",
          name: "手机约车系统",
        },
        {
          img:
            "编组 17 (1)",
          name: "智能调度系统",
        },
        {
          img:
            "位图备份 (1)",
          name: "网约车智能派单算法软件",
        },
        {
          img:
            "位图备份 2 (1)",
          name: "网约车web版管理后台软件",
        },
        {
          img:
            "位图备份 3 (1)",
          name: "上车点优化算法软件",
        },
        {
          img:
            "位图备份 4 (1)",
          name: "分时约车调度优化算法软件",
        },
        {
          img:
            "位图备份 3 (2)",
          name: "网约车分段支付算法软件",
        },
        {
          img:
            "位图备份 4 (2)",
          name: "网约车一键下单系统",
        }
      ],
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.MCopyrightBox {
  display: flex;
  overflow-x: auto;
}
.MCopyrightBox::-webkit-scrollbar {
  background: #fff;
  height: 0.266667rem;
}
.MCopyrightBox::-webkit-scrollbar-thumb {
  background: #3481ed;
  box-shadow: 0px .026667rem .266667rem 0px rgba(52, 129, 237, 0.5);
  border-radius: 0.16rem;
}
.MCopyrightBox::-webkit-scrollbar-track {
    margin: 0 .666667rem;
  border-radius: 0.133333rem;
  background: #dbe0e7;
}
.MCopyrightItem {
  padding: 0 0.2rem;
  text-align: center;
}
.MCopyrightItem >>> img {
  width: 2.933333rem;
  height: 4rem;
  margin-bottom: 0.146667rem;
}
.MCopyrightItem div {
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
  margin-bottom: 0.4rem;
}
</style>
