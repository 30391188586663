<template>
  <div class="MMapMian">
    <div id="MContainer"></div>
    <div class="MInfoContent">
      <div class="MInfoContentItem">
        <img-item imgName="形状 2" />
        <div>
          <div>公司地址：</div>
          <div>泉州市晋江市青阳街道象峰写字楼2号楼1503室</div>
        </div>
      </div>
      <div class="MInfoContentItem">
        <img-item imgName="编组 16" />
        <div>
          <div>联系方式：</div>
          <div>13661377101</div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AMap from "AMap";
export default {
  data() {
    return {};
  },
  methods: {
    createdMap() {
      // 创建地图实例
      var map = new AMap.Map("MContainer", {
        zoom: 17,
        center: [118.568655, 24.797779],
        resizeEnable: true,
      });

      // 以 icon URL 的形式创建一个途经点
      var viaMarker = new AMap.Marker({
        position: new AMap.LngLat(118.568955, 24.797779),
        // 将一张图片的地址设置为 icon
        size: (68, 85),
        icon:
          "http://dotron.cn:9000/dtron/编组 15.png",
        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
        offset: new AMap.Pixel(-68, -85),
      });

      // 将 markers 添加到地图
      map.add([viaMarker]);
    },
  },
  mounted() {
    this.createdMap();
  },
};
</script>
<style scoped>
.MMapMian {
  width: 100%;
  position: relative;
}
#MContainer {
  width: 100%;
  height: 10.666667rem;
}
.MInfoContent {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3.2rem;
  background: #000000;
  opacity: 0.7;
  color: #fff;
  font-size: .32rem;
  line-height: .44rem;
  letter-spacing: .053333rem;
}
.MInfoContentItem {
  display: flex;
  margin: .466667rem 0 0 0.8rem;
  align-items: center;
}
.MInfoContentItem >>> img {
  width: .573333rem;
  height: .573333rem;
  margin-right: .266667rem;
}
</style>
