<template>
  <div style="width: 100%; position: relative; padding: 0.933333rem 0 1.3rem">
    <display-module :titleInfo="titleInfo" style="margin-bottom: 1.066667rem" v-show="showFlag"/>
    <div class="MEnvBox">
      <div
        class="MEnvItem animate__animated animate__fadeIn animate__slow" v-show="showFlag"
        v-for="(item, index) in itemList"
        :key="index"
        @click="picClick(index)"
      >
      <div class="Medium">{{ item.name }}</div>
      <div class="itemImg">
        <img-item  :imgName="item.img" />
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import displayModule from "@/components/MDisplayModule/index";
import { ImagePreview } from "vant";
export default {
  components: {
    displayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "environment",
        CHSTitle: "公司环境",
        instructions:
          "服务客户500+，覆盖众多主流行业，更多客户正在加入我们的合作",
      },
      itemList: [
        {
          img:
            "编组 2备份 4",
          name: "会议室",
        },
        {
          img:
            "编组 2备份",
          name: "休息室",
        },
        {
          img:
            "编组 2 (1)",
          name: "外部环境",
        },
        {
          img:
            "编组 2备份 5",
          name: "技术部",
        },
        {
          img:
            "编组 2备份 3",
          name: "业务部",
        },
        {
          img:
            "编组 2备份 3",
          name: "办公区",
        },
      ],
      imgList: [],
    };
  },
  methods: {
    picClick(index) {
      ImagePreview({
        images: this.imgList,
        startPosition: index,
      });
    },
  },
  created() {
    var imgList = [];
    for (var item of this.itemList) {
      imgList.push('http://dotron.cn:9000/dtron/' + item.img + '.png');
    }
    console.log(imgList)
    this.imgList = imgList;
  },
};
</script>
<style scoped>
.MEnvBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.MEnvItem {
  width: 4.4rem;
  height: 3.2rem;
  position: relative;
  margin-bottom: 0.4rem;
}
.MEnvItem .itemImg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.MEnvItem .itemImg >>> img {
  width: 100%;
  height: 100%;
}
.flashDelay1{
  animation-delay: 1s ;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
.MEnvItem >>> div {
  position: absolute;
  width: 100%;
  bottom: 0.24rem;
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.56rem;
  letter-spacing: 0.026667rem;
  color: #fff;
  z-index: 10;
}
</style>
