<template>
  <div style="width: 100%; position: relative; padding: 2rem 0 .826667rem;">
    <display-module :titleInfo="titleInfo" style="margin-bottom: 0.8rem;position: relative;z-index: 10;" v-show="showFlag"/>
    <div class="MChoiceBox">
      <div class="MChoiceItem animate__animated animate__flipInY animate__slow"  v-show="showFlag" v-for="(item, index) in itemList">
        <img-item  :imgName="item.title" />
        <div class="MChoiceItemTitle Medium">{{ item.title }}</div>
        <div class="MChoiceItemText">{{ item.text }}</div>
      </div>
      <div class="MChoiceItem"></div>
    </div>
    <div class="MChoiceBg" v-show="showFlag">
      <img-item  imgName="abstract-architectural-design-architecture-136419" />
    </div>
    <!-- style="width:10rem;height:11.106667rem;" -->
    <div class="MChoiceBg2" v-show="showFlag" >
      <img-item  imgName="编组 12" />
    </div>
    <!-- style="width:7.04rem;height:4.76rem;" -->
  </div>
</template>

<script>
import displayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    displayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "choice",
        CHSTitle: "选择我们",
        instructions:
          "多创科技精英团队，力求用最专业的技术和服务，为全国客户提供更精准高效的一站式互联网产品设计开发方案，为企业品牌赋能！",
      },
      itemList: [
        {

          title: "周期短",
          text: "高效团队配合开发周期短",
        },
        {
          title: "系统稳",
          text: "原生开发性能质量优化",
        },
        {
          title: "品质高",
          text: "资深开发团队流程管控",
        },
        {
          title: "定制化",
          text: "专属定制满足个性需求",
        },
        {
          title: "部署快",
          text: "灵活部署快速上线",
        },
        {
          title: "成本低",
          text: "支持二次开发降低定制成本",
        },
        {
          title: "服务好",
          text: "热情服务快速响应",
        },
        {
          title: "敢创新",
          text: "敢于创新敢突破",
        },
      ],
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.MChoiceBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  z-index: 10;
  padding: 0 0.70rem;
}
.MChoiceItem {
  width: 2rem;
  margin: 0 0.426667rem;
  text-align: center;
}
.MChoiceItem >>> img {
  width: 1.226667rem;
  height: 1.226667rem;
}
.MChoiceItemTitle {
  font-size: 0.32rem;
  color: #3a416b;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
  margin: 0.24rem 0 0.2rem;
}
.MChoiceItemText {
  width: 2rem;
  height: 1.173333rem;
  font-size: 0.266667rem;
  text-align: left;
  color: #6d738e;
  line-height: 0.36rem;
  letter-spacing: 0.013333rem;
}
.MChoiceBg >>> img{
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
}
.MChoiceBg2 >>> img{
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 5.5rem;
    right: 0;
}
</style>
