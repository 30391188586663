<template>
  <div style="width: 100%; position: relative">
    <div style="width: 100%; height: 1.306667rem"></div>
    <div class="MAboutTop" style="padding-top:2.306667rem;">
      <!-- <img
        class="MTopViewImg"
        src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-af6b216e-68d1-4cf6-b184-a411c26ab033/2c2a7236-b325-48d6-9072-fe608d7b21f3.png"
      /> -->
      <div class="bgImg">
        <img-item  imgName="编组 2 (5)" />
      </div>
      <div class="MTopViewImg">
        <img-item  imgName="编组 8" />
      </div>
      <div class="MTopLine"></div>
      <div class="MTopViewText">
        多创一直致力于网络品牌的特性研究与技术创新用具备国际视野的视觉设计与互动技术
      </div>
    </div>

    <div style="width: 100%; padding:  0 0.666667rem;margin-top:  -1.146667rem;">
      <div style="text-align: right">
        <div class="MCompanyAbout Bold">about</div>
        <div class="MCompanyCHS Medium animate__animated animate__fadeInUp">
          关于多创
        </div>
      </div>
      <div class="MCompanyText animate__animated animate__fadeInUp">
        多创信息科技（泉州）有限公司成立于2019年，坐落于文化底蕴浓郁的泉州，公司专注互联网各个领域APP开发、小程序开发、微信开发、管理系统开发等一站式互联网软件定制开发服务，致力于为客户量身打造更高效的互联网应用产品解决方案。
      </div>
      <div class="MCompanyText " style="margin: 0.533333rem 0 2.333333rem">
        公司自成立以来不断自我迭代，紧跟网络信息技术不断发展，洞察瞬息万变的市场、为客户提供更优质的互联网综合服务。未来，我们不忘初心，将不断完善，结合市场需求，充分运用各方资源，发挥人才的优势，一切以客户利益需求为导向，坚决务实的为客户提供更好的互联网技术服务与支持。
      </div>
    </div>

    <div ref="imgBox">
      <div
        class="MCompanyImgBox animate__animated animate__fadeInUp"
        v-show="imgBoxShow"
      >
        <img-item  imgName="daniele-levis-pelusi-fUOTndD4Tak-unsplash" />
      </div>
    </div>

    <div ref="choice">
      <choice-part :showFlag="choiceShow"/>
    </div>
    <div ref="environment">
      <environment-part :showFlag="environmentShow"/>
    </div>
    <div ref="copyright">
      <copyright-part :showFlag="copyrightShow"/>
    </div>
    <map-part />
  </div>
</template>

<script>
import choicePart from "./components/choice";
import environmentPart from "./components/environment";
import copyrightPart from "./components/copyright";
import mapPart from "./components/map";
export default {
  name: "Mabout",
  components: {
    environmentPart,
    copyrightPart,
    mapPart,
    choicePart
  },
  data() {
    return {
      datumShow: false,
      choiceShow: false,
      environmentShow: false,
      imgBoxShow: false,
      copyrightShow: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      let _this = this;
      let refArray = [
        { ref: "datum", show: "datumShow" },
        { ref: "choice", show: "choiceShow" },
        { ref: "environment", show: "environmentShow" },
        { ref: "imgBox", show: "imgBoxShow" },
        { ref: "copyright", show: "copyrightShow" }
      ];
      refArray.forEach((r, i) => {
        _this[r.show] = _this.gdjz(r.ref, 20);
      });
    },
    gdjz(div, offset) {
      let dom = this.$refs[div];
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        return b + c > a;
      }
    }
  },
  created() {}
};
</script>
<style>
.MAboutTop {
  height: 13.4rem;
  width: 10rem;
  text-align: center;
  color: #fff;
}

.MAboutTop .bgImg{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.MAboutTop .bgImg img{
  width: 100%;
  height: 100%;
}

.MTopViewImg {
  margin: 0 auto;
  width: 4.853333rem;
  height: 2.48rem;
}

.MAboutTop .MTopViewImg img{
  position: relative;
  width: 4.853333rem;
  height: 2.48rem;
}
.MTopViewTitle {
  position: relative;
  font-family: DIN-BlackItalic, DIN;
  font-size: 0.666667rem;
  line-height: 1.2rem;
  letter-spacing: 0.026667rem;
}
.MTopLine {
  position: relative;
  width: 1.2rem;
  height: 0.106667rem;
  margin: 0.413333rem auto 0.306667rem;
  background: #2b7bee;
}
.MTopViewText {
  position: relative;
  font-size: 0.4rem;
  padding: 0 0.4rem;
  line-height: 0.56rem;
  letter-spacing: 0.026667rem;
}
.MCompanyAbout {
  opacity: 0.25;
  font-size: 0.96rem;
  font-weight: bold;
  color: rgba(213, 220, 215, 0.5);
  line-height: 1.32rem;
  background: linear-gradient(180deg, #333333 0%, #ffffff 100%);
  background-clip: text;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MCompanyCHS {
  font-size: 0.64rem;
  margin-top: -0.613333rem;
  margin-bottom: 0.56rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.866667rem;
  letter-spacing: 0.053333rem;
}
.MCompanyText {
  font-size: 0.32rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.666667rem;
  letter-spacing: 0.026667rem;
}
.MCompanyImgBox {
  margin: 0 0 0 0.666667rem;
  width: 7.586667rem;
  height: 9.32rem;
  background: #2b7bed;
  position: relative;
}
.MCompanyImgBox img {
  position: absolute;
  left: -0.56rem;
  bottom: -0.266667rem;
  width: 9.893333rem;
  height: 12.12rem;
}
</style>
